import React, { useEffect, useState } from 'react'
import Index from '../../../../component/Index'
import { styled } from "@mui/material/styles";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Autocomplete, FormControlLabel, MenuItem, Radio, RadioGroup, Select } from '@mui/material';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import moment from "moment";
import Spinner from "../../../../component/Spinner";
import { getBankRecoFilterDataApiHandler, getBankRecoPrerequisiteData, getBankRepoApiHandler, updateBankRecoDataApiHandler } from '../../../../services/AdminIndex';
import { useDispatch } from 'react-redux';
import ContainerIndex from "../../../Index";
import { toast } from 'react-toastify';
const Item = styled(Index.Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Index.Box sx={{ p: 3 }}>
                    <Index.Typography>{children}</Index.Typography>
                </Index.Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
const BankReco = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        control,
        setValue,
        formState: { errors },
        watch,

    } = Index.useForm({
        defaultValues: {
            unclearEntry: true
        }
    });
    const [currentTab, setCurrentTab] = useState(0);
    const [saveButtonLoader, setSaveButtonLoader] = useState(false);
    const handleChangeTab = (event, newValue) => {
        setCurrentTab(newValue);
    };
    const { loading, token, selectedYearStart, selectedYearEnd } =
        Index.useSelector((state) => {
            return state.ERPAdminReducer;
        });



    const [bankRecoList, setBankRecoList] = useState([])
    const [errorsDate, setErrorsDate] = useState({});
    const [selectedValue, setSelectedValue] = useState("yes");

    const [bankRecoPrerequisiteList, setBankRecoPrerequisiteList] = useState([])

    const handleSelectChange = (event, index) => {
        // setSelectedValue(event.target.value);
        const updatedList = [...bankRecoList]; // Create a copy of the list to update
        updatedList[index].bankRecoClear = event.target.value; // Update the value for the selected row
        if (event.target.value === 1) {
            if (!updatedList[index].bankRecoDate) {
                setErrorsDate(prevErrors => ({
                    ...prevErrors,
                    [`dateError${index}`]: "Date is required when 'Yes' is selected.",
                }));
            } else {
                setErrorsDate(prevErrors => {
                    const updatedErrors = { ...prevErrors };
                    delete updatedErrors[`dateError${index}`];
                    return updatedErrors;
                });
            }
        } else {
            setErrorsDate(prevErrors => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[`dateError${index}`];
                return updatedErrors;
            });
        }
        if (event.target.value !== 1) {
            updatedList[index].bankRecoDate = null; // Optionally clear date if 'No' is selected
        }
        setBankRecoList(updatedList);


    }
    const submit = (data) => {
        setSaveButtonLoader(true);
        data.fromDate = moment(new Date(data?.fromDate)).format("YYYY-MM-DD");
        data.toDate = moment(new Date(data?.toDate)).format("YYYY-MM-DD");
        data.bank = data?.bank
        data.pendingCheque = data?.pendingCheque
        data.unclearEntry = data?.unclearEntry

        try {
            getBankRecoFilterDataApiHandler(token, data).then((response) => {
                setSaveButtonLoader(false);
                if (response.status === 201 || response.status === 200) {
                    setBankRecoList(response?.data?.data)
                }
            })

        } catch (e) {
            console.log(e);
            setSaveButtonLoader(false);
        }


    }

    const fetchBankRecoPrerequisiteData = () => {
        dispatch({ type: "LOADER_START" });
        try {
            getBankRecoPrerequisiteData(token).then((response) => {
                dispatch({ type: "LOADER_START" });
                if (response.status == 201 || response.status == 200) {
                    dispatch({ type: "LOADER_END" });
                    setBankRecoPrerequisiteList(response?.data?.banks)
                } else {
                    dispatch({ type: "ADMIN_ERROR_HANDLER", payload: response });
                }
            })

        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        // Set today date if selected year is current financial year or set start date of selected year
        let currentYear = dayjs().year();
        let selectedYear = dayjs(selectedYearStart).year();
        let dateToShow = dayjs();
        if (currentYear != selectedYear) {
            dateToShow = dayjs(selectedYearStart);
        }

        setValue("fromDate", dayjs(dateToShow));
        setValue("toDate", dayjs(selectedYearEnd));
    }, [selectedYearStart]);
    useEffect(() => {
        fetchBankRecoPrerequisiteData()
    }, [])
    const handleChangeRowData = (index, field, value) => {
        // set item description dynamic
        let updatedList = [...bankRecoList];
        updatedList[index][field] = moment(value?.$d).format('YYYY-MM-DD')
        if (field === "bankRecoDate" && updatedList[index].bankRecoClear === 1) {
            // Clear the date error if the user selects a valid date
            setErrorsDate(prevErrors => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[`dateError${index}`];
                return updatedErrors;
            });
        }

        setBankRecoList(updatedList);

    };

    const submitAll = (data) => {
        // event?.preventDefault();
        setSaveButtonLoader(true)
        const validationErrors = {};
        bankRecoList.forEach((row, index) => {
            if (row.bankRecoClear === 1 && !row.bankRecoDate) {
                validationErrors[`dateError${index}`] = "Date is required when 'Yes' is selected.";
            }
        })
        if (Object.keys(validationErrors).length > 0) {
            setErrorsDate(validationErrors);
            return;
        }
        const transformedData = bankRecoList
            .filter(row => row.bankRecoClear === 1) // Only include rows with 'Yes' selected
            .map(row => ({
                id: row.id, // Make sure each row has a unique `id`
                clear: row.bankRecoClear === 1, // 'Yes' means true
                date: row.bankRecoDate ? moment(row.bankRecoDate).format('YYYY-MM-DD') : null // Format date as 'YYYY-MM-DD'
            }));

        const formData = { data: transformedData }
        try {
            updateBankRecoDataApiHandler(token, formData).then((response) => {
                setSaveButtonLoader(false);
                toast.success("Bank Reco Updated successfully");
                dispatch({ type: "LOADER_END" });
            })

        } catch (error) {
            console.log(error)
            setSaveButtonLoader(false);
        }
    }
    return (
        <div>
            <Index.Box className="main_content">
                <Index.Box className="title_top_fix">
                    <Index.Box className="flex_justify">
                        <Index.Typography variant="h4" component="h4">
                            Bank Reco
                        </Index.Typography>
                    </Index.Box>
                </Index.Box>
                <Index.Box className="web_main_content">
                    <Index.Box className="over_text_scroll">
                        <Index.Box className="admin-dashboard-list-row">
                            <form className="form-content" onSubmit={handleSubmit(submit)}>
                                <Index.Box className="report-main-box report-main-box-set">
                                    <Index.Box className="grid-main">
                                        <Index.Box
                                            display="grid"
                                            gridTemplateColumns="repeat(12, 1fr)"
                                            className="sales-order-row"
                                            gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                                        >
                                            <Index.Box
                                                gridColumn={{
                                                    xs: "span 12",
                                                    sm: "span 6",
                                                    md: "span 4",
                                                    lg: "span 4",
                                                }}
                                                className="grid-column"
                                            >

                                                <Item className="grid-item">
                                                    <Index.Box className="input-box">
                                                        <Index.Box className="form-group">
                                                            <Index.FormHelperText className="label_control">
                                                                Bank <span className="requried-star">*</span>
                                                            </Index.FormHelperText>
                                                            <Index.Box className="dropdown-box auto-drop-box">
                                                                <Controller
                                                                    control={control}
                                                                    {...register("bank", {
                                                                        required: "Bank is required.",
                                                                    })}
                                                                    render={({
                                                                        field: { onChange, value },
                                                                    }) => (
                                                                        <Autocomplete
                                                                            className="custom_form_control"
                                                                            value={

                                                                                value
                                                                                    ? bankRecoPrerequisiteList?.find(
                                                                                        (o) => o.id === value
                                                                                    )
                                                                                    : value || null
                                                                            }
                                                                            options={bankRecoPrerequisiteList || []}
                                                                            autoHighlight
                                                                            // disabled={editId ? true : false}
                                                                            onChange={(e, value) => {
                                                                                onChange(value?.id ? value?.id : "");
                                                                                // setBrokerOfParty(value?.id); 
                                                                                // setValue("challanId", []);
                                                                                // setValue("partyAddressId", "");
                                                                                // setValue("partyAddressText", "");
                                                                                // setPartyAddressDefault(
                                                                                //     value?.id,
                                                                                //     "party"
                                                                                // );
                                                                            }}
                                                                            getOptionLabel={(option) =>
                                                                                option?.accountName
                                                                            }
                                                                            renderOption={(props, option) => (
                                                                                <Index.Box
                                                                                    component="li"
                                                                                    sx={{
                                                                                        "& > img": {
                                                                                            mr: 2,
                                                                                            flexShrink: 0,
                                                                                        },
                                                                                    }}
                                                                                    {...props}
                                                                                >
                                                                                    {option?.accountName}
                                                                                </Index.Box>
                                                                            )}
                                                                            renderInput={(params) => (
                                                                                <Index.TextField
                                                                                    fullWidth
                                                                                    error={Boolean(errors?.bank)}
                                                                                    className="inner_custom_form_control"
                                                                                    {...params}
                                                                                    size="small"
                                                                                    variant="outlined"
                                                                                />
                                                                            )}
                                                                        />
                                                                    )}
                                                                />
                                                            </Index.Box>
                                                        </Index.Box>
                                                    </Index.Box>
                                                </Item>

                                            </Index.Box>

                                            <Index.Box
                                                gridColumn={{
                                                    xs: "span 12",
                                                    sm: "span 6",
                                                    md: "span 3",
                                                    lg: "span 3",
                                                }}
                                                className="grid-column"
                                            >
                                                <Item className="grid-item">
                                                    <Index.Box className="input-box">
                                                        <Index.Box className="form-group date-of-purchase">
                                                            <Index.FormHelperText className="label_control">
                                                                From Date{" "}
                                                                <span className="requried-star">*</span>
                                                            </Index.FormHelperText>

                                                            <LocalizationProvider
                                                                dateAdapter={AdapterDayjs}
                                                            >
                                                                <DemoContainer components={["DatePicker"]}>
                                                                    <Controller
                                                                        control={control}
                                                                        {...register("fromDate", {
                                                                            required: "From date is required.",
                                                                        })}
                                                                        render={({
                                                                            field: { onChange, value },
                                                                        }) => (
                                                                            <MobileDatePicker
                                                                                className="custom_form_control"
                                                                                // disableFuture={true}
                                                                                minDate={dayjs(selectedYearStart)}
                                                                                maxDate={dayjs(selectedYearEnd)}
                                                                                format="DD/MM/YYYY"
                                                                                value={value}
                                                                                onChange={(newValue) => {
                                                                                    setValue("fromDate", newValue);
                                                                                }}
                                                                                slotProps={{
                                                                                    textField: {
                                                                                        size: "small",
                                                                                        error: Boolean(errors.fromDate),
                                                                                        // error : true
                                                                                    },
                                                                                }}
                                                                                error={Boolean(errors.fromDate)}
                                                                            />
                                                                        )}
                                                                    />
                                                                </DemoContainer>
                                                            </LocalizationProvider>
                                                        </Index.Box>
                                                    </Index.Box>
                                                </Item>
                                            </Index.Box>
                                            <Index.Box
                                                gridColumn={{
                                                    xs: "span 12",
                                                    sm: "span 6",
                                                    md: "span 3",
                                                    lg: "span 3",
                                                }}
                                                className="grid-column"
                                            >
                                                <Item className="grid-item">
                                                    <Index.Box className="input-box">
                                                        <Index.Box className="form-group date-of-purchase">
                                                            <Index.FormHelperText className="label_control">
                                                                To Date{" "}
                                                                <span className="requried-star">*</span>
                                                            </Index.FormHelperText>

                                                            <LocalizationProvider
                                                                dateAdapter={AdapterDayjs}
                                                            >
                                                                <DemoContainer components={["DatePicker"]}>
                                                                    <Controller
                                                                        control={control}
                                                                        {...register("toDate", {
                                                                            required: "To date is required.",
                                                                        })}
                                                                        render={({
                                                                            field: { onChange, value },
                                                                        }) => (
                                                                            <MobileDatePicker
                                                                                className="custom_form_control"
                                                                                // disableFuture={true}
                                                                                minDate={
                                                                                    watch("fromDate")
                                                                                        ? dayjs(watch("fromDate"))
                                                                                        : dayjs(selectedYearStart)
                                                                                }
                                                                                maxDate={dayjs(selectedYearEnd)}
                                                                                format="DD/MM/YYYY"
                                                                                value={value}
                                                                                onChange={(newValue) => {
                                                                                    setValue("toDate", newValue);
                                                                                }}
                                                                                slotProps={{
                                                                                    textField: {
                                                                                        size: "small",
                                                                                        error: Boolean(errors.toDate),
                                                                                        // error : true
                                                                                    },
                                                                                }}
                                                                                error={Boolean(errors.toDate)}
                                                                            />
                                                                        )}
                                                                    />
                                                                </DemoContainer>
                                                            </LocalizationProvider>
                                                        </Index.Box>
                                                    </Index.Box>
                                                </Item>
                                            </Index.Box>
                                            <Index.Box
                                                gridColumn={{
                                                    xs: "span 12",
                                                    sm: "span 12",
                                                    md: "span 12",
                                                    lg: "span 12",
                                                }}
                                                className="grid-column"
                                            >

                                                <Item className="grid-item">
                                                    <Index.Box className="input-box">
                                                        <Index.Box className="form-group">
                                                            <Index.FormHelperText className="label_control">
                                                                Only Uncleared Entries <span className="requried-star">*</span>
                                                            </Index.FormHelperText>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="row-radio-buttons-group"
                                                                className="radio-group"
                                                                value={watch("unclearEntry")} // Binding the radio button value
                                                                onChange={(e, value) => {
                                                                    setValue("unclearEntry", value === "yes"); // Setting the selected value
                                                                }}
                                                            >
                                                                <FormControlLabel
                                                                    value="yes"
                                                                    checked={watch("unclearEntry") === true}
                                                                    control={<Radio />}
                                                                    label="Yes"
                                                                    className="radio-control"
                                                                />
                                                                <FormControlLabel
                                                                    value="no"
                                                                    checked={watch("unclearEntry") === false}
                                                                    control={<Radio />}
                                                                    label="No"
                                                                    className="radio-control"
                                                                />
                                                            </RadioGroup>
                                                        </Index.Box>
                                                    </Index.Box>
                                                </Item>

                                            </Index.Box>

                                            <Index.Box
                                                gridColumn={{
                                                    xs: "span 12",
                                                    sm: "span 12",
                                                    md: "span 12",
                                                    lg: "span 12",
                                                }}
                                                className="grid-column"
                                            >

                                                <Item className="grid-item">
                                                    <Index.Box className="input-box">
                                                        <Index.Box className="form-group">
                                                            <Index.Box className="checkbox-content-flex">
                                                                <Index.FormHelperText
                                                                    className="checkbox-lable"
                                                                // disabled={watch("billWise")}
                                                                >
                                                                    Pending cheques of Last Year
                                                                </Index.FormHelperText>
                                                                <Index.Box className="checkbox-main">
                                                                    <Controller
                                                                        control={control}
                                                                        {...register("pendingCheque")}
                                                                        render={({
                                                                            field: { onChange, value },
                                                                        }) => (
                                                                            <input
                                                                                // disabled={watch("billWise")}
                                                                                type="checkbox"
                                                                                className="checkbox"
                                                                                checked={value}
                                                                                onChange={() =>
                                                                                    setValue(
                                                                                        "pendingCheque",
                                                                                        !watch("pendingCheque")
                                                                                    )
                                                                                }
                                                                            />
                                                                        )}
                                                                    />
                                                                </Index.Box>
                                                            </Index.Box>
                                                        </Index.Box>
                                                    </Index.Box>
                                                </Item>
                                            </Index.Box>

                                        </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="btn-flex-end custom-flex-btn">
                                        <Index.Box>
                                            <Index.Box className="secondary-btn-main">
                                                <Index.Button
                                                    variant=""
                                                    className="secondary_button cancel_btn"
                                                // onClick={() => navigate(-1)}
                                                // disabled={saveButtonLoader}
                                                >
                                                    Show Reconcilation
                                                </Index.Button>
                                            </Index.Box>
                                        </Index.Box>
                                        <Index.Box className="flex-btn-custom">
                                            <Index.Box className="secondary-btn-main">
                                                <Index.Button
                                                    variant=""
                                                    className="secondary_button cancel_btn"
                                                    onClick={() => navigate(-1)}
                                                    disabled={saveButtonLoader}

                                                >
                                                    Close
                                                </Index.Button>
                                            </Index.Box>
                                            <Index.Box className="btn_linear">
                                                <Index.Button
                                                    variant=""
                                                    className="linear-btn-main savechange_btn"
                                                    disabled={saveButtonLoader}
                                                    type="submit"
                                                >
                                                    {/* Show list */}
                                                    {saveButtonLoader ? <Spinner /> : "Show list"}
                                                </Index.Button>
                                            </Index.Box>
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </form>
                            {bankRecoList?.length ? (
                                <form
                                    className="form-content"
                                    onSubmit={handleSubmit(submitAll)}
                                >
                                    <Index.Box className="common-card m-30">
                                        <Index.Box
                                            sx={{ width: "100%" }}
                                            className="tabs-outer-main"
                                        >
                                            <Index.Box
                                                sx={{ borderBottom: 1, borderColor: "divider" }}
                                            >
                                                <Index.Tabs
                                                    value={currentTab}
                                                    onChange={handleChangeTab}
                                                    aria-label="basic Tabs example"
                                                    className="tabs"
                                                >
                                                    <Index.Tab
                                                        label="Bank Reco"
                                                        {...a11yProps(0)}
                                                        className="tab"
                                                    />
                                                </Index.Tabs>
                                            </Index.Box>

                                            <CustomTabPanel
                                                value={currentTab}
                                                index={0}
                                                className="tabpanel"
                                            >
                                                <Index.Box className="mt-50">
                                                    <Index.Box className="page-table-main genrate-otherssalesentry-scrollbale-table-main">
                                                        <TableContainer
                                                            component={Paper}
                                                            className="table-container scrollable-table-container"
                                                            sx={{ maxHeight: 255 }}
                                                        >

                                                            <Table
                                                                className="table"
                                                                stickyHeader
                                                                aria-label="sticky table"
                                                            >
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell
                                                                            component="th"
                                                                            scope="row"
                                                                            className="table-th"
                                                                        >
                                                                            <Index.Box className="scrollable-box">
                                                                                Voucher No.
                                                                            </Index.Box>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            component="th"
                                                                            scope="row"
                                                                            className="table-th"
                                                                        >
                                                                            <Index.Box className="scrollable-box">
                                                                                Chq No
                                                                            </Index.Box>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            component="th"
                                                                            scope="row"
                                                                            className="table-th"
                                                                        >
                                                                            <Index.Box className="scrollable-box">
                                                                                Date
                                                                            </Index.Box>
                                                                        </TableCell>
                                                                        {/* <TableCell
                                                                            component="th"
                                                                            scope="row"
                                                                            className="table-th"
                                                                        >
                                                                            <Index.Box className="scrollable-box">
                                                                                Code
                                                                            </Index.Box>
                                                                        </TableCell> */}

                                                                        <TableCell
                                                                            component="th"
                                                                            scope="row"
                                                                            className="table-th"
                                                                        >
                                                                            <Index.Box className="scrollable-box">
                                                                                Name
                                                                            </Index.Box>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            component="th"
                                                                            scope="row"
                                                                            className="table-th"
                                                                        >
                                                                            <Index.Box className="scrollable-box">
                                                                                Amount
                                                                            </Index.Box>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            component="th"
                                                                            scope="row"
                                                                            className="table-th"
                                                                        >
                                                                            <Index.Box className="scrollable-box">
                                                                                Type
                                                                            </Index.Box>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            component="th"
                                                                            scope="row"
                                                                            className="table-th"
                                                                        >
                                                                            <Index.Box className="scrollable-box">
                                                                                Clear
                                                                            </Index.Box>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            component="th"
                                                                            scope="row"
                                                                            className="table-th"
                                                                        >
                                                                            <Index.Box className="scrollable-box">
                                                                                Reco. Date
                                                                            </Index.Box>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {bankRecoList?.map((row, index) => (
                                                                        <TableRow key={row?.id}>
                                                                            <TableCell
                                                                                component="td"
                                                                                scope="row"
                                                                                className="table-td"
                                                                            >
                                                                                <Index.Box className="scrollable-box">
                                                                                    {row?.voucherNo}
                                                                                </Index.Box>
                                                                            </TableCell>
                                                                            <TableCell
                                                                                component="td"
                                                                                scope="row"
                                                                                className="table-td"
                                                                            >
                                                                                <Index.Box className="scrollable-box">
                                                                                    {row?.chequeNo}
                                                                                </Index.Box>
                                                                            </TableCell>
                                                                            <TableCell
                                                                                component="td"
                                                                                scope="row"
                                                                                className="table-td"
                                                                            >
                                                                                <Index.Box className="scrollable-box">
                                                                                    {/* {row?.voucherDate} */}
                                                                                    {moment(row?.voucherDate).format(
                                                                                        "DD/MM/YYYY"
                                                                                    )}
                                                                                </Index.Box>
                                                                            </TableCell>
                                                                            {/* 
                                                                            <TableCell
                                                                                component="td"
                                                                                scope="row"
                                                                                className="table-td"
                                                                            >
                                                                                <Index.Box className="scrollable-box">
                                                                                    {row?.code}
                                                                                </Index.Box>
                                                                            </TableCell> */}
                                                                            <TableCell
                                                                                component="td"
                                                                                scope="row"
                                                                                className="table-td"
                                                                            >
                                                                                <Index.Box className="scrollable-box">
                                                                                    {row?.accountName}
                                                                                </Index.Box>
                                                                            </TableCell>

                                                                            <TableCell
                                                                                component="td"
                                                                                scope="row"
                                                                                className="table-td"
                                                                            >
                                                                                <Index.Box className="scrollable-box">
                                                                                    {row?.amount}
                                                                                </Index.Box>
                                                                            </TableCell>
                                                                            <TableCell
                                                                                component="td"
                                                                                scope="row"
                                                                                className="table-td"
                                                                            >
                                                                                <Index.Box className="scrollable-box">
                                                                                    {row?.paymentType === "Payment" ? "P" : row?.paymentType === "Receipt" ? "R" : row?.paymentType}
                                                                                </Index.Box>
                                                                            </TableCell>
                                                                            <TableCell
                                                                                component="td"
                                                                                scope="row"
                                                                                className="table-td"
                                                                            >
                                                                                <Index.Box className="form-group">
                                                                                    <Index.Box className="scrollable-box dropdown-box auto-drop-box">
                                                                                        <Select
                                                                                            // labelId="yes-no-select-label"
                                                                                            value={row?.bankRecoClear}
                                                                                            onChange={(e) => handleSelectChange(e, index)}
                                                                                            // label="Yes/No"
                                                                                            className="custom-select-dropdown custom_form_control"
                                                                                        >
                                                                                            <MenuItem value={1} className='custom-menuItem-table'>Yes</MenuItem>
                                                                                            <MenuItem value={0} className='custom-menuItem-table'>No</MenuItem>
                                                                                        </Select>
                                                                                    </Index.Box>
                                                                                </Index.Box>
                                                                            </TableCell>
                                                                            <TableCell
                                                                                component="td"
                                                                                scope="row"
                                                                                className="table-td"
                                                                            >
                                                                                <Index.Box className="scrollable-box">
                                                                                    {/* {row?.amount} */}
                                                                                    <Index.Box className="input-box">
                                                                                        <Index.Box className="form-group date-of-purchase">
                                                                                            <LocalizationProvider
                                                                                                dateAdapter={
                                                                                                    AdapterDayjs
                                                                                                }
                                                                                            >
                                                                                                <DemoContainer
                                                                                                    components={[
                                                                                                        "DatePicker",
                                                                                                    ]}
                                                                                                >
                                                                                                    <MobileDatePicker
                                                                                                        className="custom_form_control custom-date-form-control"
                                                                                                        minDate={dayjs(
                                                                                                            selectedYearStart
                                                                                                        )}
                                                                                                        maxDate={dayjs(
                                                                                                            selectedYearEnd
                                                                                                        )}

                                                                                                        format="DD/MM/YYYY"
                                                                                                        value={dayjs(
                                                                                                            ContainerIndex.convertDate(
                                                                                                                row?.bankRecoDate ||
                                                                                                                ""
                                                                                                            )
                                                                                                        )}
                                                                                                        onChange={(
                                                                                                            newValue
                                                                                                        ) => {
                                                                                                            handleChangeRowData(
                                                                                                                index, "bankRecoDate", newValue
                                                                                                            );
                                                                                                        }}
                                                                                                        disabled={row.bankRecoClear !== 1}
                                                                                                        slotProps={{
                                                                                                            textField: {
                                                                                                                className: errorsDate[`dateError${index}`] ? 'input-error' : '',
                                                                                                                size: "small",
                                                                                                                error: errorsDate[`dateError${index}`] ? true : false,
                                                                                                                // error: true,
                                                                                                                // helperText: errorsDate[`dateError${index}`],
                                                                                                            },
                                                                                                        }}


                                                                                                    // error={checkIncludeError(
                                                                                                    //     `lrDate${indexI}`
                                                                                                    // )}
                                                                                                    />
                                                                                                </DemoContainer>
                                                                                            </LocalizationProvider>
                                                                                        </Index.Box>
                                                                                    </Index.Box>
                                                                                </Index.Box>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>

                                                        </TableContainer>
                                                    </Index.Box>
                                                </Index.Box>
                                            </CustomTabPanel>
                                        </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="btn-flex-end">
                                        <Index.Box className="btn_linear">
                                            <Index.Button
                                                variant=""
                                                className="linear-btn-main savechange_btn"
                                                type="submit"
                                                disabled={saveButtonLoader}
                                            >
                                                {saveButtonLoader ? <Spinner /> : "Save"}

                                            </Index.Button>
                                        </Index.Box>
                                        <Index.Box className="secondary-btn-main">
                                            <Index.Button
                                                variant=""
                                                className="secondary_button cancel_btn"
                                            // onClick={() => navigate(-1)}
                                            // disabled={saveButtonLoader}
                                            >
                                                Back
                                            </Index.Button>
                                        </Index.Box>
                                        <Index.Box className="secondary-btn-main">
                                            <Index.Button
                                                variant=""
                                                className="secondary_button cancel_btn"
                                            // onClick={() => navigate(-1)}
                                            // disabled={saveButtonLoader}
                                            >
                                                Close
                                            </Index.Button>
                                        </Index.Box>

                                    </Index.Box>
                                </form>
                            ) : (
                                <></>
                            )}
                        </Index.Box>

                    </Index.Box>
                </Index.Box>
            </Index.Box>
        </div>
    )
}

export default BankReco
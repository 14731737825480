import React, { useEffect, useState } from 'react'
import Index from '../../../Index';
import { useDispatch } from 'react-redux';
import { deleteChequeEnvelopeData, getChequeAndEvelopeListData } from '../../../../services/AdminIndex';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

function paginate(array, rowsPerPage, page_number) {
    return array.slice(
        (page_number - 1) * rowsPerPage,
        page_number * rowsPerPage
    );
}

const ChequeEnvelopeList = () => {
    const { id } = useParams();
    const editId = id;
    console.log(id, "")
    const navigate = Index.useNavigate();
    const dispatch = useDispatch();
    const { loading, token, selectedYearStart, selectedYearEnd } =
        Index.useSelector((state) => {
            return state.ERPAdminReducer;
        });

    const [chequeEnvelopelistData, setChequeEnvelopeData] = useState([])
    const [searchedData, setSearchedData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const fetchChequeEnvelopeData = () => {
        // dispatch({ type: "LOADER_START" });
        try {

            getChequeAndEvelopeListData(token).then((response) => {
                // dispatch({ type: "LOADER_START" });
                if (response.status == 201 || response.status == 200) {
                    // dispatch({ type: "LOADER_END" });
                    console.log(response, "listResponse")
                    setChequeEnvelopeData(response?.data)
                }
            })

        } catch (error) {
            console.log(error);
        }
    }
    const rowsPerPage = 10;
    useEffect(() => {
        //Page count
        const countData = Math.ceil(searchedData?.length / rowsPerPage);
        setCount(countData);
        setFilterData(searchedData);
    }, [page, searchedData]);

    useEffect(() => {
        fetchChequeEnvelopeData()
    }, [])
    useEffect(() => {
        setSearchedData(chequeEnvelopelistData);
        setFilterData(chequeEnvelopelistData);
    }, [chequeEnvelopelistData]);
    const handleSearchData = (search) => {
        // if (!search) return setSearchedData(salesEntryData);
        // 👇️ take parameter passed from Child component
        const result = searchedData?.filter((item) => {
            console.log(item, "searcjIte")
            return (
                (String(item?.bank) || "").toLowerCase().includes(search?.toLowerCase()) ||
                (String(item?.type) || "").toLowerCase().includes(search?.toLowerCase())
            );
        });
        setFilterData(result);
        setPage(1);
    };

    const deleteChequeEnvelope = (id) => {
        dispatch({ type: "LOADER_START" });
        deleteChequeEnvelopeData(token, id).then((response) => {
            if (response.status === 201 || response.status === 200) {
                toast.success(response?.message);
                dispatch({ type: "LOADER_END" });
                fetchChequeEnvelopeData()
            } else {
                dispatch({ type: "ADMIN_ERROR_HANDLER", payload: response });
            }

        })
    }

    // Delete Record function

    const deletehandler = async (id) => {
        Index.Swal.fire({
            title: "",
            text: "Are you sure you want to delete this record ?",
            icon: "",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
        }).then(async (result) => {
            if (result.isConfirmed) {
                deleteChequeEnvelope(id);
            }
        });

        // try {
        //     deleteChequeEnvelopeData(token, id).then((response) => {
        //         console.log(response, "deleteResponse")
        //         fetchChequeEnvelopeData()
        //     })

        // } catch (error) {
        //     console.log(error);
        // }
    }
    return (
        <Index.Box className="main_content">
            <Index.Box className="title_top_fix">
                <Index.Box className="flex_justify">
                    <Index.Typography variant="h4" component="h4">
                        Cheque & Envelope
                    </Index.Typography>
                    <Index.Box className="search_list_main">
                        <Index.Box className="search_input form-group">
                            <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="custom_form_control search_control"
                                placeholder="Search"
                                onChange={(e) => handleSearchData(e.target.value)}
                            />
                            <Index.Box className="search_btn">
                                <Index.Button className="btn_search" disableRipple>
                                    <img
                                        src={Index.Png.Search}
                                        className="search"
                                        alt="Search"
                                    ></img>
                                </Index.Button>
                            </Index.Box>
                        </Index.Box>


                        <Index.LinearButton
                            btnLabel="Add"
                            onClick={() => {
                                navigate("/dashboard/Cheque-print");
                            }}
                            className="linear-btn-main add_btn"
                        />

                    </Index.Box>
                </Index.Box>
            </Index.Box>
            <Index.Box className="web_main_content">
                <Index.Box className="over_text_scroll">
                    <Index.Box className="admin-dashboard-list-row">
                        <Index.Box sx={{ width: 1 }} className="grid-main">
                            <Index.Box
                                display="grid"
                                className="display-row"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                            >
                                <Index.Box
                                    gridColumn={{
                                        xs: "span 12",
                                        sm: "span 12",
                                        md: "span 12",
                                        lg: "span 12",
                                    }}
                                    className="grid-column"
                                >
                                    <Index.Box className="report-main-box">
                                        <Index.Box className="page-table-main other-sales-entry-list-table-main">
                                            <Index.TableContainer
                                                component={Index.Paper}
                                                className="table-container padding-container"
                                            >
                                                <Index.Table
                                                    sx={{ minWidth: 650 }}
                                                    aria-label="simple table"
                                                    className="table"
                                                >
                                                    <Index.TableHead className="table-head">
                                                        <Index.TableRow className="table-row">
                                                            <Index.TableCell
                                                                component="th"
                                                                variant="th"
                                                                className="table-th"
                                                                width="2%"
                                                            >
                                                                Sr.
                                                            </Index.TableCell>
                                                            <Index.TableCell
                                                                component="th"
                                                                variant="th"
                                                                className="table-th"
                                                                width="8%"
                                                            >
                                                                Type
                                                            </Index.TableCell>

                                                            <Index.TableCell
                                                                component="th"
                                                                variant="th"
                                                                className="table-th"
                                                                width="8%"
                                                            >
                                                                Bank
                                                            </Index.TableCell>

                                                            <Index.TableCell
                                                                component="th"
                                                                variant="th"
                                                                className="table-th"
                                                                width="7%"
                                                            >
                                                                Created At
                                                            </Index.TableCell>

                                                            <Index.TableCell
                                                                component="th"
                                                                variant="th"
                                                                className="table-th"
                                                                width="15%"
                                                            >
                                                                Action
                                                            </Index.TableCell>

                                                        </Index.TableRow>
                                                    </Index.TableHead>
                                                    <Index.TableBody className="table-body">
                                                        {filterData?.length > 0 ? (
                                                            paginate(filterData, rowsPerPage, page)?.map(
                                                                (row, index) => (
                                                                    <Index.TableRow
                                                                        key={row?.id}
                                                                    >
                                                                        <Index.TableCell
                                                                            component="td"
                                                                            variant="td"
                                                                            scope="row"
                                                                            className="table-td"
                                                                        >
                                                                            {((page - 1) * rowsPerPage) + index + 1}
                                                                        </Index.TableCell>
                                                                        <Index.TableCell
                                                                            component="td"
                                                                            variant="td"
                                                                            className="table-td"
                                                                        >
                                                                            {row?.type === "cheque_print" ? "Cheque Print" : row?.type === "envelope_print" ? "Envelope Print" : row?.type}
                                                                        </Index.TableCell>
                                                                        <Index.TableCell
                                                                            component="td"
                                                                            variant="td"
                                                                            className="table-td"
                                                                        >
                                                                            {row?.bankName ? row?.bankName : "-"}
                                                                        </Index.TableCell>
                                                                        <Index.TableCell
                                                                            component="td"
                                                                            variant="td"
                                                                            className="table-td"
                                                                        >

                                                                            {moment(row?.createdAt).format("DD-MM-YYYY")}
                                                                        </Index.TableCell>
                                                                        <Index.TableCell
                                                                            component="td"
                                                                            variant="td"
                                                                            className="table-td"
                                                                        >
                                                                            <Index.Box className="userdata-btn-flex">
                                                                                <Index.Tooltip
                                                                                    title="Edit"
                                                                                    placement="top"
                                                                                >
                                                                                    <Index.Box
                                                                                        className="table-btns table-edit-btn"
                                                                                        disableRipple
                                                                                        // to={`/dashboard/edit-Cheque-Envelope-list/${row?.id}`}
                                                                                        onClick={() => navigate("/dashboard/Cheque-print", { state: row })}
                                                                                    >
                                                                                        <img
                                                                                            src={Index.Svg.whiteedit}
                                                                                            className="table-icons"
                                                                                        ></img>

                                                                                    </Index.Box>

                                                                                </Index.Tooltip>
                                                                                <Index.Tooltip
                                                                                    title="Delete"
                                                                                    placement="top"
                                                                                >
                                                                                    <Index.Link
                                                                                        className="table-btns table-eye-btn"
                                                                                        disableRipple
                                                                                        onClick={() => {
                                                                                            deletehandler(row?.id);
                                                                                        }}
                                                                                    >
                                                                                        <img
                                                                                            src={Index.Svg.whitetrash}
                                                                                            className="table-icons"
                                                                                        ></img>
                                                                                    </Index.Link>

                                                                                </Index.Tooltip>
                                                                            </Index.Box>
                                                                        </Index.TableCell>


                                                                    </Index.TableRow>
                                                                )
                                                            )
                                                        ) : (
                                                            <Index.TableRow>
                                                                <Index.TableCell
                                                                    className="no-record-found-text"
                                                                    colSpan={7}
                                                                    align="center"
                                                                >
                                                                    No record found
                                                                </Index.TableCell>
                                                            </Index.TableRow>
                                                        )}


                                                    </Index.TableBody>
                                                </Index.Table>

                                            </Index.TableContainer>
                                            <Index.Box className="pagination-table-design">
                                                <Index.Stack spacing={2} className="stack-pagination">
                                                    <Index.Pagination
                                                        count={Math.ceil(filterData?.length / rowsPerPage)}
                                                        page={page}
                                                        onChange={handleChangePage}
                                                        variant="outlined"
                                                        shape="rounded"
                                                        className="stack-pagination-list"
                                                    />
                                                </Index.Stack>
                                            </Index.Box>
                                        </Index.Box>
                                    </Index.Box>

                                </Index.Box>

                            </Index.Box>

                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Box>

        </Index.Box>
    )
}

export default ChequeEnvelopeList
import React, { useEffect, useState } from 'react'
import Index from '../../../../component/Index'
import { Autocomplete, Checkbox, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { styled } from "@mui/material/styles";
import { CheckBox } from '@mui/icons-material';
import { addUpdateChequeEnvelopeData, getChequeEnvelopePrerequisiteData } from '../../../../services/AdminIndex';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Spinner from "../../../../component/Spinner";
import { toast } from 'react-toastify';
const Item = styled(Index.Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));
function ChequePrint() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [selectedBank, setSelectedBank] = useState(null);
    console.log(state, "state1321")
    const { loading, token, selectedYearStart, selectedYearEnd } =
        Index.useSelector((state) => {
            return state.ERPAdminReducer;
        });
    const bankOptions = [
        { id: 1, accountName: "Bank of America" },
        { id: 2, accountName: "Chase Bank" },
        { id: 3, accountName: "Wells Fargo" },
        { id: 4, accountName: "Citibank" },
        { id: 5, accountName: "HSBC" }
    ];
    const {
        register,
        handleSubmit,
        control,
        setValue,
        formState: { errors },
        watch,
        getValues
    } = Index.useForm({
        defaultValues: {
            spaceFromTop: state?.spaceFromTop ? JSON.parse(state?.spaceFromTop) : {
                date: 0,
                acPayee: 0,
                partyName: 0,
                rsInWords: 0,
                rsInFigure: 0
            },
            spaceFromLeft: state?.spaceFromLeft ? JSON.parse(state?.spaceFromLeft) : {
                date: 0,
                acPayee: 0,
                partyName: 0,
                rsInWords: 0,
                rsInWordsLine2: 0,
                rsInFigure: 0
            },
            features: state?.features ? JSON.parse(state?.features) : {
                acPayeeBold: false,
                dateBold: false,
                partyNameBold: false,
                inWordsBold: false,
                inFigure: false

            },
            spaceFromTopEnvelope: state?.spaceFromTop ? JSON.parse(state?.spaceFromTop) : {
                to: 0,
                partyNameEnvelope: 0,
                address1: 0,
                address2: 0,
                address3: 0,
                city: 0,
                telNo: 0

            },
            spaceFromLeftEnvelope: state?.spaceFromLeft ? JSON.parse(state?.spaceFromLeft) : {
                to: 0,
                partyNameEnvelope: 0,
                address1: 0,
                address2: 0,
                address3: 0,
                city: 0,
                telNo: 0
            },
            featuresEnvelope: state?.features ? JSON.parse(state?.features) : {

                toBold: false,
                partyBold: false,
                address1Bold: false,
                address2Bold: false,
                address3Bold: false,
                cityBold: false,
                telNoBold: false

            },

            bank: state?.bank || ""


        }

    });

    const [saveButtonLoader, setSaveButtonLoader] = useState(false);
    const [print, setPrint] = useState(state?.type ? state?.type : "cheque_print")
    const [chequeEnvelopePrerequisiteList, setChequeEnvelopePrerequisiteList] = useState([])
    console.log(chequeEnvelopePrerequisiteList, "888")
    const [previewValues, setPreviewValues] = useState({})
    const [previewValuesEnvelope, setPreviewValuesEnvelope] = useState({})
    const [showPreview, setShowPreview] = useState(false);
    console.log(showPreview, "show12321")
    const handlePrint = (event) => {
        const printValue = event.target.value;
        console.log(printValue, '8977978');
        setPrint(printValue);
        setValue('print', printValue); // Set the form value manually for "print"
    };

    const submitAll = (data) => {
        setSaveButtonLoader(true);
        let requestData;
        const editId = state?.id;


        if (print === "cheque_print") {
            data.bank = data.bank
            data.print = data.print || print
            // cheque_print values

            const spaceFromTop = {
                date: data.spaceFromTop?.date || 0,
                acPayee: data.spaceFromTop?.acPayee || 0,
                partyName: data.spaceFromTop?.partyName || 0,
                rsInWords: data.spaceFromTop?.rsInWords || 0,
                rsInFigure: data.spaceFromTop?.rsInFigure || 0,
            };

            const spaceFromLeft = {
                date: data.spaceFromLeft?.date || 0,
                acPayee: data.spaceFromLeft?.acPayee || 0,
                partyName: data.spaceFromLeft?.partyName || 0,
                rsInWords: data.spaceFromLeft?.rsInWords || 0,
                rsInWordsLine2: data.spaceFromLeft?.rsInWordsLine2 || 0,
                rsInFigure: data.spaceFromLeft?.rsInFigure || 0,
            };

            const features = {
                acPayeeBold: data.features?.acPayeeBold || false,
                dateBold: data.features?.dateBold || false,
                partyNameBold: data.features?.partyNameBold || false,
                inWordsBold: data.features?.inWordsBold || false,
                inFigure: data.features?.inFigure || false,
            };
            data.features = features
            data.spaceFromTop = spaceFromTop
            data.spaceFromLeft = spaceFromLeft

            requestData = {
                id: editId,
                bank: data.bank,
                type: data.print || print,
                spaceFromTop: JSON.stringify(spaceFromTop),
                spaceFromLeft: JSON.stringify(spaceFromLeft),
                features: JSON.stringify(features),
            };
            // console.log("Cheque Print Data:", chequePrintData);
            // const formData = { data: chequePrintData }
            // console.log("Cheque Print Data formData:", formData);


        } else {
            // data.bank = data.bank
            data.print = data.print || print
            const spaceFromTop = {
                to: data.spaceFromTopEnvelope?.to || 0,
                partyNameEnvelope: data.spaceFromTopEnvelope?.partyNameEnvelope || 0,
                address1: data.spaceFromTopEnvelope?.address1 || 0,
                address2: data.spaceFromTopEnvelope?.address2 || 0,
                address3: data.spaceFromTopEnvelope?.address3 || 0,
                city: data.spaceFromTopEnvelope?.city || 0,
                telNo: data.spaceFromTopEnvelope?.telNo || 0,
            }
            const spaceFromLeft = {
                to: data.spaceFromLeftEnvelope?.to || 0,
                partyNameEnvelope: data.spaceFromLeftEnvelope?.partyNameEnvelope || 0,
                address1: data.spaceFromLeftEnvelope?.address1 || 0,
                address2: data.spaceFromLeftEnvelope?.address2 || 0,
                address3: data.spaceFromLeftEnvelope?.address3 || 0,
                city: data.spaceFromLeftEnvelope?.city || 0,
                telNo: data.spaceFromLeftEnvelope?.telNo || 0,
            }
            const features = {
                toBold: data.featuresEnvelope.toBold || false,
                partyBold: data.featuresEnvelope.partyBold || false,
                address1Bold: data.featuresEnvelope.address1Bold || false,
                address2Bold: data.featuresEnvelope.address2Bold || false,
                address3Bold: data.featuresEnvelope.address3Bold || false,
                cityBold: data.featuresEnvelope.cityBold || false,
                telNoBold: data.featuresEnvelope.telNoBold || false,

            }

            requestData = {
                // bank: null,
                id: editId,
                type: data.print || print,
                spaceFromTop: JSON.stringify(spaceFromTop),
                spaceFromLeft: JSON.stringify(spaceFromLeft),
                features: JSON.stringify(features),
            };

            console.log(requestData, "chequeEnvelopeData")


        }


        try {
            addUpdateChequeEnvelopeData(token, requestData).then((response) => {
                setSaveButtonLoader(false);
                console.log(response, "addChequeDataResponse")

                if (response?.status == 201 || response?.status == 200) {
                    toast.success(response?.message)
                    navigate("/dashboard/Cheque-Envelope-list")
                } else {
                    toast.error(response.message)
                }

            })

        } catch (error) {
            // toast.error(error)
            console.log(error);
            setSaveButtonLoader(false);
        }

    }

    const handlePreview = () => {
        const values = getValues(); // Get all form values
        console.log("previewValues", values); // You can log or use the values here
        // You can also perform other actions like navigating to a preview page, etc.
        setPreviewValues(values)
        setShowPreview(true);
    };

    const handlePreviewEnvelope = () => {
        const values = getValues();
        console.log(values, "previewValuesInEnvople")
        setPreviewValuesEnvelope(values)
        setShowPreview(true);
    }

    const fetchChequeEnvelopePrerequisiteData = () => {
        dispatch({ type: "LOADER_START" });
        try {
            getChequeEnvelopePrerequisiteData(token).then((response) => {
                dispatch({ type: "LOADER_START" });
                if (response.status == 201 || response.status == 200) {
                    dispatch({ type: "LOADER_END" });
                    console.log(response, "resposeData")
                    setChequeEnvelopePrerequisiteList(response?.data?.banks)
                }
            })

        } catch (error) {
            console.log(error);
            dispatch({ type: "LOADER_END" });
        }
    }
    useEffect(() => {
        fetchChequeEnvelopePrerequisiteData()
    }, [])

    // useEffect(() => {
    //     if (state?.bank) {
    //         setValue('bank', state.bank); // Manually set the bank field value
    //     }
    // }, [state, setValue, chequeEnvelopePrerequisiteList]);
    useEffect(() => {
        if (chequeEnvelopePrerequisiteList?.length > 0 && state) {
            const foundBank = chequeEnvelopePrerequisiteList.find((o) => o.id === state.bank);
            if (foundBank) {
                setSelectedBank(foundBank);
            }
        }
    }, [chequeEnvelopePrerequisiteList, state]);
    return (
        <div>
            <Index.Box className="main_content">
                <Index.Box className="title_top_fix">
                    <Index.Box className="flex_justify">
                        <Index.Typography variant="h4" component="h4">
                            Cheque Print
                        </Index.Typography>
                    </Index.Box>
                </Index.Box>
                <Index.Box className="web_main_content">
                    <Index.Box className="over_text_scroll">
                        <Index.Box className="admin-dashboard-list-row">
                            <form className="form-content" onSubmit={handleSubmit(submitAll)}>
                                <Index.Box className="report-main-box report-main-box-set">
                                    <Index.Box className="grid-main">
                                        <Index.Box
                                            display="grid"
                                            gridTemplateColumns="repeat(12, 1fr)"
                                            className="sales-order-row"
                                            gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                                        >
                                            <Index.Box
                                                gridColumn={{
                                                    xs: "span 12",
                                                    sm: "span 6",
                                                    md: "span 4",
                                                    lg: "span 4",
                                                }}
                                                className="grid-column"
                                            >
                                                <Item className="grid-item">
                                                    <Index.Box className="input-box">
                                                        <Index.Box className="form-group">
                                                            <Index.FormHelperText className="label_control">
                                                                Print <span className="requried-star">*</span>
                                                            </Index.FormHelperText>
                                                            <Index.Box className="dropdown-box auto-drop-box">
                                                                <Controller
                                                                    control={control}
                                                                    name="print"
                                                                    // {...register("print", {
                                                                    //     required: "Print is required.",
                                                                    // })}

                                                                    render={({
                                                                        field: { onChange, value },
                                                                    }) => (
                                                                        <Select
                                                                            className="custom_form_control select-custom-form-control"
                                                                            value={print || value}
                                                                            disabled={state ? true : false}
                                                                            onChange={

                                                                                (e) => {
                                                                                    onChange(e.target.value);
                                                                                    handlePrint(e);
                                                                                    setValue("print", e.target.value)

                                                                                }
                                                                            }

                                                                        >
                                                                            <MenuItem value={"cheque_print"} className='custom-menuItem-dropdrown inner_custom_form_control'>Cheque Print </MenuItem>
                                                                            <MenuItem value={"envelope_print"} className='custom-menuItem-dropdrown inner_custom_form_control'>Envelope Print </MenuItem>
                                                                        </Select>
                                                                    )}
                                                                />
                                                            </Index.Box>
                                                        </Index.Box>
                                                    </Index.Box>
                                                </Item>
                                            </Index.Box>
                                            {print == "cheque_print" && (
                                                <Index.Box
                                                    gridColumn={{
                                                        xs: "span 12",
                                                        sm: "span 6",
                                                        md: "span 4",
                                                        lg: "span 4",
                                                    }}
                                                    className="grid-column"
                                                >

                                                    <Item className="grid-item">
                                                        <Index.Box className="input-box">
                                                            <Index.Box className="form-group">
                                                                <Index.FormHelperText className="label_control">
                                                                    Bank <span className="requried-star">*</span>
                                                                </Index.FormHelperText>
                                                                <Index.Box className="dropdown-box auto-drop-box">
                                                                    <Controller
                                                                        control={control}
                                                                        {...register("bank", {
                                                                            required: "Bank is required.",
                                                                        })}
                                                                        render={({
                                                                            field: { onChange, value },
                                                                        }) => (
                                                                            <Autocomplete
                                                                                className="custom_form_control"
                                                                                value={selectedBank || null}
                                                                                options={chequeEnvelopePrerequisiteList || []}
                                                                                autoHighlight
                                                                                disabled={state ? true : false}
                                                                                onChange={(e, newValue) => {
                                                                                    onChange(newValue?.id || "");
                                                                                    setValue("bank", newValue?.id)
                                                                                    // setBrokerOfParty(value?.id); 
                                                                                    // setValue("challanId", []);
                                                                                    // setValue("partyAddressId", "");
                                                                                    // setValue("partyAddressText", "");
                                                                                    // setPartyAddressDefault(
                                                                                    //     value?.id,
                                                                                    //     "party"
                                                                                    // );
                                                                                }}
                                                                                getOptionLabel={(option) =>
                                                                                    option?.accountName
                                                                                }
                                                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                                                renderOption={(props, option) => (
                                                                                    <Index.Box
                                                                                        component="li"
                                                                                        sx={{
                                                                                            "& > img": {
                                                                                                mr: 2,
                                                                                                flexShrink: 0,
                                                                                            },
                                                                                        }}
                                                                                        {...props}
                                                                                    >
                                                                                        {option?.accountName}
                                                                                    </Index.Box>
                                                                                )}
                                                                                renderInput={(params) => (
                                                                                    <Index.TextField
                                                                                        fullWidth
                                                                                        error={Boolean(errors?.bank)}
                                                                                        className="inner_custom_form_control"
                                                                                        {...params}
                                                                                        size="small"
                                                                                        variant="outlined"
                                                                                    />
                                                                                )}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Index.Box>
                                                            </Index.Box>
                                                        </Index.Box>
                                                    </Item>

                                                </Index.Box>
                                            )}


                                        </Index.Box>

                                        {
                                            print == "cheque_print" ? (
                                                <>
                                                    <Index.Box className="print-cheque-main-box">

                                                        <Index.Box
                                                            display="grid"
                                                            gridTemplateColumns="repeat(12, 1fr)"
                                                            className="sales-order-row"
                                                            gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                                                        >
                                                            <Index.Box
                                                                gridColumn={{
                                                                    xs: "span 4",
                                                                    sm: "span 4",
                                                                    md: "span 4",
                                                                    lg: "span 4",
                                                                }}
                                                                className="grid-column"
                                                            >
                                                                <Item className="grid-item">
                                                                    <Index.Box className="chequePrint-table-box">
                                                                        <Index.Box className="chequePrint-table-header">
                                                                            <Index.Typography>
                                                                                SPACE FROM TOP
                                                                                <span className="sub-text">(Space Count From Previous Field)</span>
                                                                            </Index.Typography>
                                                                        </Index.Box>
                                                                        <Index.Box className="chequePrint-table-item">
                                                                            {[
                                                                                { label: 'Date', name: 'spaceFromTop.date' },
                                                                                { label: 'A/C Payee', name: 'spaceFromTop.acPayee' },
                                                                                { label: 'Party Name', name: 'spaceFromTop.partyName' },
                                                                                { label: 'Rs. In Words', name: 'spaceFromTop.rsInWords' },
                                                                                { label: 'Rs. In Figure', name: 'spaceFromTop.rsInFigure' },

                                                                            ].map(({ label, name }) => (<>
                                                                                <Index.Box className="flex-chequePrint-item" key={name}>
                                                                                    <Index.Box className='chequePrint-item-text'>
                                                                                        <Index.Typography>
                                                                                            {label}
                                                                                        </Index.Typography>
                                                                                    </Index.Box>
                                                                                    <Index.Box className='chequePrint-item-input-field form-group'>
                                                                                        <TextField className='custom_form_control' key={label} {...register(name)} type='number' />
                                                                                    </Index.Box>
                                                                                </Index.Box>
                                                                            </>
                                                                            ))}
                                                                        </Index.Box>
                                                                    </Index.Box>
                                                                </Item>

                                                            </Index.Box>
                                                            <Index.Box
                                                                gridColumn={{
                                                                    xs: "span 4",
                                                                    sm: "span 4",
                                                                    md: "span 4",
                                                                    lg: "span 4",
                                                                }}
                                                                className="grid-column"
                                                            >
                                                                <Item className="grid-item">
                                                                    <Index.Box className="chequePrint-table-box">
                                                                        <Index.Box className="chequePrint-table-header">
                                                                            <Index.Typography>
                                                                                SPACE FROM Left
                                                                            </Index.Typography>
                                                                        </Index.Box>
                                                                        <Index.Box className="chequePrint-table-item">
                                                                            {[
                                                                                { label: 'Date', name: 'spaceFromLeft.date' },
                                                                                { label: 'A/C Payee', name: 'spaceFromLeft.acPayee' },
                                                                                { label: 'Party Name', name: 'spaceFromLeft.partyName' },
                                                                                { label: 'Rs. In Words', name: 'spaceFromLeft.rsInWords' },
                                                                                { label: 'Rs. In Words Line2', name: 'spaceFromLeft.rsInWordsLine2' },
                                                                                { label: 'Rs. In Figure', name: 'spaceFromLeft.rsInFigure' },
                                                                            ].map(({ label, name }) => (<>
                                                                                <Index.Box className="flex-chequePrint-item">
                                                                                    <Index.Box className='chequePrint-item-text'>
                                                                                        <Index.Typography>
                                                                                            {label}
                                                                                        </Index.Typography>
                                                                                    </Index.Box>
                                                                                    <Index.Box className='chequePrint-item-input-field form-group '>
                                                                                        <TextField className='custom_form_control' key={label} {...register(name)} type='number' />
                                                                                    </Index.Box>
                                                                                </Index.Box>
                                                                            </>
                                                                            ))}
                                                                        </Index.Box>
                                                                    </Index.Box>
                                                                </Item>



                                                            </Index.Box>
                                                            <Index.Box

                                                                gridColumn={{
                                                                    xs: "span 4",
                                                                    sm: "span 4",
                                                                    md: "span 4",
                                                                    lg: "span 4",
                                                                }}
                                                                className="grid-column"

                                                            >
                                                                <Item className="grid-item">
                                                                    <Index.Box className="chequePrint-table-box">
                                                                        <Index.Box className="chequePrint-table-header">
                                                                            <Index.Typography>
                                                                                FEATURES
                                                                            </Index.Typography>
                                                                        </Index.Box>
                                                                        <Index.Box className="chequePrint-table-item">
                                                                            {[
                                                                                { label: 'AC Payee Bold', name: 'features.acPayeeBold' },
                                                                                { label: 'Date Bold', name: 'features.dateBold' },
                                                                                { label: 'Party Name Bold', name: 'features.partyNameBold' },
                                                                                { label: 'In Words Bold', name: 'features.inWordsBold' },
                                                                                { label: 'In Figure', name: 'features.inFigure' },


                                                                            ].map(({ label, name }) => (<>
                                                                                <Index.Box className="flex-chequePrint-item custom-flex-chequePrint-item chequePrint-item-text" key={name}>
                                                                                    {/* <CheckBox className='custom-check-box' key={label} {...register(name)} /> */}
                                                                                    <Controller
                                                                                        name={name}
                                                                                        defaultValue={false}
                                                                                        control={control}
                                                                                        render={({ field }) => (
                                                                                            <Checkbox
                                                                                                {...field}
                                                                                                className="custom-check-box"
                                                                                                checked={field.value}


                                                                                            />
                                                                                        )}

                                                                                    />
                                                                                    <Index.Typography>
                                                                                        {label}
                                                                                    </Index.Typography>
                                                                                </Index.Box>
                                                                            </>
                                                                            ))}
                                                                        </Index.Box>
                                                                    </Index.Box>
                                                                </Item>
                                                            </Index.Box>
                                                        </Index.Box>
                                                        <Index.Box className="btn-flex-end custom-btn-flex-center">
                                                            <Index.Box className="secondary-btn-main">
                                                                <Index.Button
                                                                    variant=""
                                                                    className="secondary_button cancel_btn"
                                                                    // onClick={() => navigate(-1)}
                                                                    // disabled={saveButtonLoader}

                                                                    onClick={handlePreview}
                                                                >
                                                                    Preview
                                                                </Index.Button>

                                                            </Index.Box>
                                                        </Index.Box>
                                                    </Index.Box>

                                                </>
                                            ) : (
                                                <>
                                                    <Index.Box className="print-cheque-main-box">
                                                        <Index.Box
                                                            display="grid"
                                                            gridTemplateColumns="repeat(12, 1fr)"
                                                            className="sales-order-row"
                                                            gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                                                        >
                                                            <Index.Box
                                                                gridColumn={{
                                                                    xs: "span 4",
                                                                    sm: "span 4",
                                                                    md: "span 4",
                                                                    lg: "span 4",
                                                                }}
                                                                className="grid-column"
                                                            >
                                                                <Item className="grid-item">
                                                                    <Index.Box className="chequePrint-table-box">
                                                                        <Index.Box className="chequePrint-table-header">
                                                                            <Index.Typography>
                                                                                SPACE FROM TOP
                                                                                <span className="sub-text">(Space Count From Previous Field)</span>
                                                                            </Index.Typography>
                                                                        </Index.Box>

                                                                        <Index.Box className="chequePrint-table-item">
                                                                            {[
                                                                                { label: 'To', name: 'spaceFromTopEnvelope.to' },
                                                                                { label: 'Party Name', name: 'spaceFromTopEnvelope.partyNameEnvelope' },
                                                                                { label: 'Address 1', name: 'spaceFromTopEnvelope.address1' },
                                                                                { label: 'Address 2', name: 'spaceFromTopEnvelope.address2' },
                                                                                { label: 'Address 3', name: 'spaceFromTopEnvelope.address3' },
                                                                                { label: 'City', name: 'spaceFromTopEnvelope.city' },
                                                                                { label: 'Tel No.', name: 'spaceFromTopEnvelope.telNo' },
                                                                            ].map(({ label, name }) => (<>
                                                                                <Index.Box className="flex-chequePrint-item" key={name}>
                                                                                    <Index.Box className='chequePrint-item-text'>
                                                                                        <Index.Typography>
                                                                                            {label}
                                                                                        </Index.Typography>
                                                                                    </Index.Box>
                                                                                    <Index.Box className='chequePrint-item-input-field form-group'>
                                                                                        <TextField className='custom_form_control' key={label} type='number' {...register(name)} />
                                                                                    </Index.Box>
                                                                                </Index.Box>
                                                                            </>
                                                                            ))}
                                                                        </Index.Box>
                                                                    </Index.Box>
                                                                </Item>
                                                            </Index.Box>
                                                            <Index.Box
                                                                gridColumn={{
                                                                    xs: "span 4",
                                                                    sm: "span 4",
                                                                    md: "span 4",
                                                                    lg: "span 4",
                                                                }}
                                                                className="grid-column"
                                                            >
                                                                <Item className="grid-item">
                                                                    <Index.Box className="chequePrint-table-box">
                                                                        <Index.Box className="chequePrint-table-header">
                                                                            <Index.Typography>
                                                                                SPACE FROM Left
                                                                            </Index.Typography>
                                                                        </Index.Box>

                                                                        <Index.Box className="chequePrint-table-item">
                                                                            {[
                                                                                { label: 'To', name: 'spaceFromLeftEnvelope.to' },
                                                                                { label: 'Party Name', name: 'spaceFromLeftEnvelope.partyNameEnvelope' },
                                                                                { label: 'Address 1', name: 'spaceFromLeftEnvelope.address1' },
                                                                                { label: 'Address 2', name: 'spaceFromLeftEnvelope.address2' },
                                                                                { label: 'Address 3', name: 'spaceFromLeftEnvelope.address3' },
                                                                                { label: 'City', name: 'spaceFromLeftEnvelope.city' },
                                                                                { label: 'Tel No.', name: 'spaceFromLeftEnvelope.telNo' },
                                                                            ].map(({ label, name }) => (<>
                                                                                <Index.Box className="flex-chequePrint-item" key={name}>
                                                                                    <Index.Box className='chequePrint-item-text'>
                                                                                        <Index.Typography>
                                                                                            {label}
                                                                                        </Index.Typography>
                                                                                    </Index.Box>
                                                                                    <Index.Box className='chequePrint-item-input-field form-group'>
                                                                                        <TextField className='custom_form_control' key={label} type='number' {...register(name)} />
                                                                                    </Index.Box>
                                                                                </Index.Box>
                                                                            </>
                                                                            ))}
                                                                        </Index.Box>
                                                                    </Index.Box>
                                                                </Item>
                                                            </Index.Box>
                                                            <Index.Box
                                                                gridColumn={{
                                                                    xs: "span 4",
                                                                    sm: "span 4",
                                                                    md: "span 4",
                                                                    lg: "span 4",
                                                                }}
                                                                className="grid-column"
                                                            >
                                                                <Item className="grid-item">
                                                                    <Index.Box className="chequePrint-table-box">
                                                                        <Index.Box className="chequePrint-table-header">
                                                                            <Index.Typography>
                                                                                FEATURES
                                                                            </Index.Typography>
                                                                        </Index.Box>
                                                                        <Index.Box className="chequePrint-table-item">
                                                                            {[
                                                                                { label: 'To Bold', name: 'featuresEnvelope.toBold' },
                                                                                { label: 'Party Bold', name: 'featuresEnvelope.partyBold' },
                                                                                { label: 'Address 1 Bold', name: 'featuresEnvelope.address1Bold' },
                                                                                { label: 'Address 2 Bold', name: 'featuresEnvelope.address2Bold' },
                                                                                { label: 'Address 3 Bold', name: 'featuresEnvelope.address3Bold' },
                                                                                { label: 'City Bold', name: 'featuresEnvelope.cityBold' },
                                                                                { label: 'Tel. No . Bold', name: 'featuresEnvelope.telNoBold' },
                                                                            ].map(({ label, name }) => (<>
                                                                                <Index.Box className="flex-chequePrint-item custom-flex-chequePrint-item chequePrint-item-text" key={name}>
                                                                                    <Controller
                                                                                        name={name}
                                                                                        defaultValue={false}
                                                                                        control={control}
                                                                                        render={({ field }) => (
                                                                                            <Checkbox
                                                                                                {...field}
                                                                                                className="custom-check-box"
                                                                                                checked={field.value}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                    <Index.Typography>
                                                                                        {label}
                                                                                    </Index.Typography>
                                                                                </Index.Box>
                                                                            </>
                                                                            ))}
                                                                        </Index.Box>
                                                                    </Index.Box>
                                                                </Item>
                                                            </Index.Box>
                                                        </Index.Box>
                                                        <Index.Box className="btn-flex-end custom-btn-flex-center">
                                                            <Index.Box className="secondary-btn-main">
                                                                <Index.Button
                                                                    variant=""
                                                                    className="secondary_button cancel_btn"
                                                                    // onClick={() => navigate(-1)}
                                                                    // disabled={saveButtonLoader}
                                                                    onClick={handlePreviewEnvelope}
                                                                >
                                                                    Preview
                                                                </Index.Button>

                                                            </Index.Box>
                                                        </Index.Box>
                                                    </Index.Box>
                                                </>
                                            )
                                        }

                                    </Index.Box>
                                    <Index.Box className="btn-flex-end">
                                        <Index.Box className="secondary-btn-main">
                                            <Index.Button
                                                variant=""
                                                className="secondary_button cancel_btn"
                                            // onClick={() => navigate(-1)}
                                            // disabled={saveButtonLoader}
                                            >
                                                Cancel
                                            </Index.Button>
                                        </Index.Box>
                                        <Index.Box className="btn_linear">
                                            <Index.Button
                                                variant=""
                                                className="linear-btn-main savechange_btn"
                                                type="submit"
                                                disabled={saveButtonLoader}
                                            >
                                                {saveButtonLoader ? <Spinner /> : "Save"}

                                            </Index.Button>
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </form>
                        </Index.Box>

                        {showPreview &&
                            (
                                print === "cheque_print" ? (
                                    <Index.Box className="preview-main">
                                        <Index.Box className="report-main-box">
                                            <Index.Box className="cheque-main">
                                                <Index.Box className="cheque-main-box">
                                                    <TableContainer>
                                                        <Table>
                                                            <TableBody>
                                                                {/* Date */}
                                                                <TableRow>
                                                                    <TableCell sx={{ width: "100%", fontWeight: previewValues?.features?.dateBold == true ? 'bold' : "normal" }}>
                                                                        <Index.Box className="" sx={{
                                                                            pl: previewValues?.spaceFromLeft?.date ? `${previewValues?.spaceFromLeft?.date}em` : '0em',
                                                                            pt: previewValues?.spaceFromTop?.date ? `${previewValues?.spaceFromTop?.date}em` : '0em',
                                                                        }}>
                                                                            22/01/2025
                                                                        </Index.Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                                {/* A/c Payee */}
                                                                <TableRow>
                                                                    <TableCell sx={{ width: "100%", fontWeight: previewValues?.features?.acPayeeBold == true ? 'bold' : "normal" }}>
                                                                        <Index.Box sx={{
                                                                            pl: previewValues?.spaceFromLeft?.acPayee ? `${previewValues?.spaceFromLeft?.acPayee}em` : '0em',
                                                                            pt: previewValues?.spaceFromTop?.acPayee ? `${previewValues?.spaceFromTop?.acPayee}em` : '0em',
                                                                        }}>
                                                                            A/c Payee
                                                                        </Index.Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                                {/* Company Name */}
                                                                <TableRow>
                                                                    <TableCell sx={{ width: "100%", fontWeight: previewValues?.features?.partyNameBold == true ? 'bold' : "normal" }}>
                                                                        <Index.Box
                                                                            sx={{
                                                                                pl: previewValues?.spaceFromLeft?.partyName ? `${previewValues?.spaceFromLeft?.partyName}em` : '0em',
                                                                                pt: previewValues?.spaceFromTop?.partyName ? `${previewValues?.spaceFromTop?.partyName}em` : '0em',
                                                                            }}
                                                                        >
                                                                            Tri Trac Technologies Pvt. Ltd.
                                                                        </Index.Box>
                                                                    </TableCell>
                                                                </TableRow>

                                                                {/* Amount in Words */}
                                                                <TableRow>
                                                                    <TableCell sx={{ width: "100%", fontWeight: previewValues?.features?.inWordsBold == true ? 'bold' : "normal" }}>
                                                                        <Index.Box
                                                                            sx={{
                                                                                pl: previewValues?.spaceFromLeft?.rsInWords ? `${previewValues?.spaceFromLeft?.rsInWords}em` : '0em',
                                                                                pt: previewValues?.spaceFromTop?.rsInWords ? `${previewValues?.spaceFromTop?.rsInWords}em` : '0em',
                                                                            }}
                                                                        >
                                                                            SEVENTY SEVEN LAKH SEVENTY THOUSAND SEVEN HUNDRED SEVENTY
                                                                        </Index.Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                                {/* Amount in Numbers  */}
                                                                <TableRow>
                                                                    <TableCell sx={{
                                                                        width: "100%",
                                                                        fontWeight: previewValues?.features?.inFigure == true ? 'bold' : "normal"

                                                                    }}>
                                                                        <Index.Box className="table-footer-flex">
                                                                            <Index.Box>
                                                                                SEVEN ONLY
                                                                            </Index.Box>
                                                                            <Index.Box
                                                                                sx={{
                                                                                    pl: previewValues?.spaceFromLeft?.rsInFigure ? `${previewValues?.spaceFromLeft?.rsInFigure}em` : '0em',
                                                                                    pt: previewValues?.spaceFromTop?.rsInFigure ? `${previewValues?.spaceFromTop?.rsInFigure}em` : '0em',
                                                                                }}
                                                                            >
                                                                                7777777.00
                                                                            </Index.Box>

                                                                        </Index.Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Index.Box>
                                            </Index.Box>
                                        </Index.Box>
                                    </Index.Box>
                                ) : (

                                    <Index.Box className="preview-main">
                                        <Index.Box className="report-main-box">
                                            <Index.Box className="cheque-main">
                                                <Index.Box className="cheque-main-box">
                                                    <TableContainer>
                                                        <Table>
                                                            <TableBody>
                                                                {/* to */}
                                                                <TableRow>
                                                                    <TableCell sx={{ width: "100%", fontWeight: previewValuesEnvelope?.featuresEnvelope?.toBold == true ? 'bold' : "normal" }}>
                                                                        <Index.Box className="" sx={{
                                                                            pl: previewValuesEnvelope?.spaceFromLeftEnvelope?.to ? `${previewValuesEnvelope?.spaceFromLeftEnvelope?.to}em` : '0em',
                                                                            pt: previewValuesEnvelope?.spaceFromTopEnvelope?.to ? `${previewValuesEnvelope?.spaceFromTopEnvelope?.to}em` : '0em',
                                                                        }}>
                                                                            To
                                                                        </Index.Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                                {/* Company name */}
                                                                <TableRow>
                                                                    <TableCell sx={{ width: "100%", fontWeight: previewValuesEnvelope?.featuresEnvelope?.partyBold == true ? 'bold' : "normal" }}>
                                                                        <Index.Box sx={{
                                                                            pl: previewValuesEnvelope?.spaceFromLeftEnvelope?.partyNameEnvelope ? `${previewValuesEnvelope?.spaceFromLeftEnvelope?.partyNameEnvelope}em` : '0em',
                                                                            pt: previewValuesEnvelope?.spaceFromTopEnvelope?.partyNameEnvelope ? `${previewValuesEnvelope?.spaceFromTopEnvelope?.partyNameEnvelope}em` : '0em',
                                                                        }}>
                                                                            Tri Trac Technologies Pvt Ltd.
                                                                        </Index.Box>
                                                                    </TableCell>
                                                                </TableRow>


                                                                {/* Address 1 */}
                                                                <TableRow>
                                                                    <TableCell sx={{ width: "100%", fontWeight: previewValuesEnvelope?.featuresEnvelope?.address1Bold == true ? 'bold' : "normal" }}>
                                                                        <Index.Box
                                                                            sx={{
                                                                                pl: previewValuesEnvelope?.spaceFromLeftEnvelope?.address1 ? `${previewValuesEnvelope?.spaceFromLeftEnvelope?.address1}em` : '0em',
                                                                                pt: previewValuesEnvelope?.spaceFromTopEnvelope?.address1 ? `${previewValuesEnvelope?.spaceFromTopEnvelope?.address1}em` : '0em',
                                                                            }}
                                                                        >
                                                                            Office No. 15,Ambika complex
                                                                        </Index.Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                                {/* Address 2 */}
                                                                <TableRow>
                                                                    <TableCell sx={{ width: "100%", fontWeight: previewValuesEnvelope?.featuresEnvelope?.address2Bold == true ? 'bold' : "normal" }}>
                                                                        <Index.Box
                                                                            sx={{
                                                                                pl: previewValuesEnvelope?.spaceFromLeftEnvelope?.address2 ? `${previewValuesEnvelope?.spaceFromLeftEnvelope?.address2}em` : '0em',
                                                                                pt: previewValuesEnvelope?.spaceFromTopEnvelope?.address2 ? `${previewValuesEnvelope?.spaceFromTopEnvelope?.address2}em` : '0em',
                                                                            }}
                                                                        >
                                                                            Near Ambe Mata Temple
                                                                        </Index.Box>
                                                                    </TableCell>
                                                                </TableRow>

                                                                {/* Address 3 */}
                                                                <TableRow>
                                                                    <TableCell sx={{ width: "100%", fontWeight: previewValuesEnvelope?.featuresEnvelope?.address3Bold == true ? 'bold' : "normal" }}>
                                                                        <Index.Box
                                                                            sx={{
                                                                                pl: previewValuesEnvelope?.spaceFromLeftEnvelope?.address3 ? `${previewValuesEnvelope?.spaceFromLeftEnvelope?.address3}em` : '0em',
                                                                                pt: previewValuesEnvelope?.spaceFromTopEnvelope?.address3 ? `${previewValuesEnvelope?.spaceFromTopEnvelope?.address3}em` : '0em',
                                                                            }}
                                                                        >
                                                                            Navghar Vasai(East)
                                                                        </Index.Box>
                                                                    </TableCell>
                                                                </TableRow>

                                                                {/* city */}
                                                                <TableRow>
                                                                    <TableCell sx={{ width: "100%", fontWeight: previewValuesEnvelope?.featuresEnvelope?.cityBold == true ? 'bold' : "normal" }}>
                                                                        <Index.Box
                                                                            sx={{
                                                                                pl: previewValuesEnvelope?.spaceFromLeftEnvelope?.city ? `${previewValuesEnvelope?.spaceFromLeftEnvelope?.city}em` : '0em',
                                                                                pt: previewValuesEnvelope?.spaceFromTopEnvelope?.city ? `${previewValuesEnvelope?.spaceFromTopEnvelope?.city}em` : '0em',
                                                                            }}
                                                                        >
                                                                            Palghar -401210 ,Maharashtra
                                                                        </Index.Box>
                                                                    </TableCell>
                                                                </TableRow>

                                                                {/* tel */}
                                                                <TableRow>
                                                                    <TableCell sx={{ width: "100%", fontWeight: previewValuesEnvelope?.featuresEnvelope?.telNoBold == true ? 'bold' : "normal" }}>
                                                                        <Index.Box
                                                                            sx={{
                                                                                pl: previewValuesEnvelope?.spaceFromLeftEnvelope?.telNo ? `${previewValuesEnvelope?.spaceFromLeftEnvelope?.telNo}em` : '0em',
                                                                                pt: previewValuesEnvelope?.spaceFromTopEnvelope?.telNo ? `${previewValuesEnvelope?.spaceFromTopEnvelope?.telNo}em` : '0em',
                                                                            }}
                                                                        >
                                                                            Tel.  :0250-2390306
                                                                        </Index.Box>
                                                                    </TableCell>
                                                                </TableRow>

                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Index.Box>
                                            </Index.Box>
                                        </Index.Box>
                                    </Index.Box>

                                )

                            )
                        }

                    </Index.Box>
                </Index.Box>
            </Index.Box>
        </div >
    )
}

export default ChequePrint